<template>
  <Layout>
    <PageHeader :title="$t('fixed_assets.categories')" />
    <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'" class="row">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="
                        background-color: #2a3042 !important;
                        wdith: 250px !important;
                      "
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <router-link to="/add_asset_category">
                  <button type="button" class="btn btn-light float-end mb-4">
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                    <th scope="col">{{ $t("fixed_assets.category") }}</th>
                    <th scope="col">
                      {{ $t("popups.code") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.add_asset") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.ac_code") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.ac_asset") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, index) in accounts" :key="account">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? account.name : account.name_en
                      }}
                    </td>
                    <td>
                      <div>
                        {{ account?.level_three_code }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                          @keyup="selectedAccount(account)"
                          v-model="account.search"
                          :suggestions="filteredAccounts"
                          @complete="account.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="searchAccounts(account)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                    <td>
                      <div v-if="account?.depreciable">
                        {{ account?.ad_level_three_code }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <div class="d-flex" v-if="account?.depreciable">
                        <AutoComplete
                          @keyup="selectedAdAccount(account)"
                          v-model="account.ad_search"
                          :suggestions="filteredAdccounts"
                          @complete="account.ad_search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="searchAdAccounts(account)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                      <div v-else>-</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end  table -->

      <!--end-->
      <!--   Apps  Pagination     -->
      <div>
        <ul class="pagination pagination-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page - 1)"
              aria-label="Previous"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in typesTotalPages"
            :key="p"
          >
            <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
          </li>

          <li class="page-item" :class="{ disabled: page == typesTotalPages }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page + 1)"
              aria-label="Next"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      // start fixed assets types
      accounts: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAdAccountId: null,
      levelThreeAccounts: [],
      levelThreeAdAccounts: [],
    };
  },
  components: { Layout, PageHeader },
  methods: {
    get() {
      this.http.get("fixed-assets/types").then((res) => {
        this.accounts = res.data;
      });
    },
    selectedAccount(account) {
      this.http
        .post("fixed-assets/types/setup/accounts/search", {
          search: account.search,
          type: "account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    selectedAdAccount(account) {
      this.http
        .post("fixed-assets/types/setup/accounts/search", {
          search: account.ad_search,
          type: "ad account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAdAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    searchAccounts(account) {
      this.http
        .put("fixed-assets/setup", account.id, { ...account, type: "account" })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = [];
            console.log("done");
            account.search = "";
            this.get(this.page);
          }
        });
    },
    searchAdAccounts(account) {
      this.levelThreeAdAccountId = account?.ad_search?.split("-")[0];
      this.http
        .put("fixed-assets/setup", account.id, {
          ...account,
          type: "ad account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAdAccounts = [];
            console.log("done");
            account.ad_search = "";
            this.get(this.page);
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    filteredAdccounts() {
      return this.levelThreeAdAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.get(1);
  },
};
</script>
